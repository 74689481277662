// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ads-an-idea-js": () => import("./../../../src/pages/ads/an-idea.js" /* webpackChunkName: "component---src-pages-ads-an-idea-js" */),
  "component---src-pages-ads-modern-business-plan-js": () => import("./../../../src/pages/ads/modern-business-plan.js" /* webpackChunkName: "component---src-pages-ads-modern-business-plan-js" */),
  "component---src-pages-ads-online-business-plan-js": () => import("./../../../src/pages/ads/online-business-plan.js" /* webpackChunkName: "component---src-pages-ads-online-business-plan-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-real-estate-business-plan-software-js": () => import("./../../../src/pages/real-estate-business-plan-software.js" /* webpackChunkName: "component---src-pages-real-estate-business-plan-software-js" */),
  "component---src-pages-restaurant-business-plan-software-js": () => import("./../../../src/pages/restaurant-business-plan-software.js" /* webpackChunkName: "component---src-pages-restaurant-business-plan-software-js" */),
  "component---src-pages-salon-spa-studio-business-plan-software-js": () => import("./../../../src/pages/salon-spa-studio-business-plan-software.js" /* webpackChunkName: "component---src-pages-salon-spa-studio-business-plan-software-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

